import React from 'react'
import { Link } from 'gatsby'

import toothBuddyLogo from '../images/tooth_buddy_logo.png'

const NavBar = () => {
    return (
        <div className="flex flex-row sticky top-0 bg-black justify-between items-center bg-opacity-60 backdrop-blur-lg drop-shadow-lg z-50">
            <Link to="/">
                <div className="flex flex-row items-center">
                    <img className="h-24" src={toothBuddyLogo} alt="Tooth Buddy Logo" />
                    <text className='font-bold text-xl md:text-5xl md:pb-3 mx-4 text-orange-300 drop-shadow-md'>Tooth Buddy</text>
                </div>  
            </Link>

            <div className="flex flex-row space-x-6 justify-between mx-20 text-xl text-orange-300">
                <Link to="/">Home</Link>
                <Link to="/shop">Shop</Link>
            </div>
        </div>
    )
}

export default NavBar
import React from 'react'
import NavBar from '../components/NavBar'

const forkids = () => {
    return (
        <div>
            <NavBar />
            <div>forkids</div>
        </div>
    )
}

export default forkids